const zh = {
  LVL0001: "该字段为必填项",
  LVL0002: "只接受字母、数字和连字符",
  LVL0003: "无效的电子邮箱地址",
  LVL0004: "只接受字母，数字",
  LVL0005: "只接受数字",
  LVL0006: "只接受字母、数字和空格",
  LVL0007: "国家编码是必填的",
  LVL0008: "电话长度为8 ~ 23位",
  LVL0009: "无效密码",
  LVL0010: "错误密码",
  LVL0011: "英文",
  LVL0012: "货架监控算法",
  LVL0013: "保存",
  LVL0014: "自动检测SKU在左侧注册照片",
  LVL0015: "印度尼西亚",
  LVL0016: "日文",
  LVL0017: "中文",
  LVL0018: "电话号码",
  LVL0019: "密码",
  LVL0020: "创建密码",
  LVL0021: "公司简介",
  LVL0022: "编辑用户简介",
  LVL0023: "",
  LVL0024: "查看简介",
  LVL0025: "查看简介",
  LVL0026: "清算",
  LVL0027: "套餐信息",
  LVL0028: "暂无内容",
  LVL0029: "修改失败",
  LVL0030: "产品定价信息",
  LVL0031: "只能输入数字且必须是整数",
  LVL0032: "西班牙语",
  LVL0033: "德语",
  LVL0034: "切换公司",

  LVLDB0001: "出口看板",
  LVLDB0002: "位置",
  LVLDB0003: "零售商",
  LVLDB0004: "门店",
  LVLDB0005: "设备",
  LVLDB0006: "数据访问控制",
  LVLDB0007: "车间",
  LVLDB0008: "语言",
  LVLDB0009: "每页记录数",
  LVLDB0010: "未找到记录.",
  LVLDB0011: "确认",
  LVLDB0012: "取消",
  LVLDB0013: "广告商",
  LVLDB0014: "获取图片",
  LVLDB0015: "获取结果",
  LVLDB0016: "重置",
  LVLDB0017: "预览",
  LVLDB0018: "编辑广告商",
  LVLDB0019: "门店名称",
  LVLDB0020: "预览",
  LVLDB0021: "编辑",
  LVLDB0022: "删除",
  LVLDB0023: "重新发送邮件",
  LVLDB0024: "新增广告商",
  LVLDB0025: "地址",
  LVLDAC0031: "广告商名称",
  LVLDAC0032: "个人资料",
  LVLDAC0033: "用户信息",
  LVLDAC0034: "忘记密码",
  LVLDAC0035: "创建账户",

  LVLDAC0038: "公司名称",
  LVLDAC0036: "密钥",
  LVLDAC0037: "应用 Id",
  LVLDAC0039: "公司编码",
  LVLDB0040: "查看图片",

  LVLDB0027: "查看广告商",
  LVLDB0028: "发送",
  LVLDB0029: "返回登录页",
  LVLDB0030: "登录",
  LVLDB0031: "注册",
  LVLDB0032: "编辑设备",
  LVLDB0033: "序列号",
  LVLDB0034: "退出登录",
  LVLDB0035: "加载中...",
  LVLDB0037: "查看权限",
  LVLDB0038: "编辑权限",
  LVLDB0036: "权限管理",

  LVLDB0039: "重置密码",

  LVLGF0001: "门店国家",
  LVLGF0002: "省份/州",
  LVLGF0003: "城市",
  LVLGF0004: "城市时区",
  LVLGF0005: "零售商",
  LVLGF0006: "门店",
  LVLGF0007: "门店类型",
  LVLGF0008: "重置",
  LVLGF0009: "查询",
  LVLGF0010: "商户国家",
  LVLGF0011: "没有可选项",
  LVLGF0012: "国家",
  LVLGF0013: "门店",

  LVLDAC0001: "权限控制",
  LVLDAC0002: "设备",
  LVLDAC0003: "零售商",
  LVLDAC0004: "广告商",
  LVLDAC0005: "选择一个软件来分配数据访问",
  LVLDAC0006: "取消",
  LVLDAC0007: "允许访问",
  LVLDAC0008: "设备编号",
  LVLDAC0009: "虚拟名称",
  LVLDAC0010: "设备型号",
  LVLDAC0011: "设备类型",
  LVLDAC0012: "门店",
  LVLDAC0013: "状态",
  LVLDAC0014: "操作",

  LVLDAC0018: "广告商名称",
  LVLDAC0019: "广告商代码",
  LVLDAC0020: "邮箱",
  LVLDAC0021: "电话",
  LVLDAC0022: "地址",
  LVLDAC0023: "国家",
  LVLDAC0024: "州",
  LVLDAC0025: "城市",
  LVLDAC0026: "密码不匹配",
  LVLDAC0027: "设置密码",
  LVLDAC0028: "新密码",
  LVLDAC0029: "确定要删除所选记录吗?",
  LVLDAC0030: "确认密码",

  LVLWS0001: "工厂",
  LVLWS0002: "内容管理",
  LVLWS0003: "零售人工智能分析",
  LVLWS0004: "价格标签",
  LVLWS0005: "刷新成功 !",

  LVLOT0001: "门店列表",
  LVLOT0002: "零售商",
  LVLOT0003: "门店名称",
  LVLOT0004: "门店类型",
  LVLOT0005: "国家",
  LVLOT0006: "省份/州",
  LVLOT0007: "城市",
  LVLOT0008: "城市区域",
  LVLOT0009: "时区",
  LVLOT0010: "操作",
  LVLOT0011: "新增门店",
  LVLOT0012: "地址",
  LVLOT0013: "纬度",
  LVLOT0014: "经度",
  LVLOT0015: "返回",
  LVLOT0016: "提交",
  LVLOT0017: "编辑门店",
  LVLOT0018: "查看门店",
  LVLOT0019: "门店 连接到设备",
  LVLOT0020: "门店删除成功",
  LVLOT0021: "门店在这个国家的位置",
  LVLOT0022: "零售门店管理",

  LVLRC0001: "零售商",
  LVLRC0002: "广告商名称",
  LVLRC0003: "广告商代码",
  LVLRC0004: "电子邮箱",
  LVLRC0005: "电话",
  LVLRC0006: "地址",
  LVLRC0007: "国家",
  LVLRC0008: "州/省份",
  LVLRC0009: "城市",
  LVLRC0010: "操作",
  LVLRC0011: "按名称查询",
  LVLRC0023: "零售商名称",
  LVLRC0024: "零售商编码",

  LVLRC0012: "新增零售商",
  LVLRC0013: "用户名称",
  LVLRC0014: "姓氏",
  LVLRC0015: "电子邮箱",
  LVLRC0016: "提交",
  LVLRC0017: "返回",
  LVLRC0018: "编辑零售商",
  LVLRC0019: "查看零售商",
  LVLRC0020: "国家编码",
  LVLRC0021: "确定要删除所选记录吗?",
  LVLRC0022: "零售商",

  LVLRC0025: "零售商编码",
  LVLRC0026: "请输入零售商编码...",
  LVLRC0027: "地址",
  LVLRC0028: "查看",
  LVLRC0029: "编辑",
  LVLRC0030: "用户列表",
  LVLRC0031: "零售客户管理",
  LVLRC0039: "设备 SN",
  LVLRC0040: "设备状态",
  LVLRC0041: "设备名称",
  LVLRC0042: "门店名称",
  LVLRC0043: "请入门店名称",
  LVLRC0044: "输入设备名称",
  LVLRC0045: "输入设备 SN",
  LVLRC0046: "输入设备状态",
  LVLRC0047: "设备数量",
  LVLRC0048: "有效时间",
  LVLRC0049: "订阅开始时间",

  LVLRC0032: "公司简介",
  LVLRC0033: "名称",
  LVLRC0034: "零售商编码",
  LVLRC0035: "电子邮箱",
  LVLRC0036: "电话",
  LVLRC0037: "名称",
  LVLRC0038: "请输入零售商名称...",

  LVLRCP001: "用户列表",
  LVLRCP002: "用户名称",
  LVLRCP003: "用户ID",
  LVLRCP004: "电子邮箱",
  LVLRCP005: "电话",
  LVLRCP006: "加入日期",
  LVLRCP007: "名称不能超过100个字符",
  LVLRCP008: "姓氏不能超过25个字符",
  LVLRCP009: "用户ID不能超过20个字符",
  LVLRCP010: "手机号码是必填的",
  LVLRCP011: "创建用户",
  LVLRCP012: "只允许*JPEG, *JPG, *PNG 格式",
  LVLRCP013: "最大为3MB",
  LVLRCP014: "名称",
  LVLRCP015: "姓氏",
  LVLRCP016: "DD-MM-YYYY (请选择日期)",
  LVLRCP017: "编辑人员",
  LVLRCP018: "查看人员",
  LVLRCP019: "加入日期不能是将来的日期",
  LVLRCP020: "无效的加入日期!",
  LVLRCP021: "组织属性",
  LVLRCP022: "点击这上传图片",
  LVLRCP023: "密码",
  LVLRCP024: "确认密码",
  LVLRCP025: "请输入密码.",
  LVLRCP026: "请重新输入密码",
  LVLRCP027: "原始密码",
  LVLRCP028: "新密码",
  LVLRCP029: "用户头像",

  LVLLOC0001: "国家名称",
  LVLLOC0002: "国家编码",
  LVLLOC0003: "操作",
  LVLLOC0004: "确定要删除所选记录吗?",
  LVLLOC0005: "同时拥有州和门店的国家不能被删除",
  LVLLOC0006: "拥有州的国家不能被删除",
  LVLLOC0007: "位置",

  LVLLOC0008: "州名称",
  LVLLOC0009: "州编码",
  LVLLOC0010: "国家",
  LVLLOC0011: "操作",
  LVLLOC0012: "确定要删除所选记录吗?",
  LVLLOC0013: "同时拥有城市和出口的州不能被删除",
  LVLLOC0014: "有城市的州不能删除",

  LVLLOC0015: "城市名称",
  LVLLOC0016: "城市编码",
  LVLLOC0017: "州",
  LVLLOC0018: "操作",
  LVLLOC0019: "确定要删除所选记录吗?",
  LVLLOC0020: "拥有门店的城市不能被删除",

  LVLLOC0021: "出口类型名称",
  LVLLOC0022: "出口类型代码",
  LVLLOC0023: "操作",
  LVLLOC0024: "确定要删除所选记录吗?",
  LVLLOC0025: "门店类型删除成功",

  LVLLOC0026: "城市名称",
  LVLLOC0027: "城市区号",
  LVLLOC0028: "操作",
  LVLLOC0029: "确定要删除所选记录吗?",

  LVLLOC0030: "门店类型链接到商店",
  LVLLOC0031: "城市时区连接门店",
  LVLLOC0032: "城市时区删除成功",

  LVLLOC0033: "新增国家",
  LVLLOC0034: "名称",
  LVLLOC0035: "编号",
  LVLLOC0036: "提交",
  LVLLOC0037: " 返回",

  LVLLOC0038: " 编辑国家",

  LVLLOC0039: " 新增状态",
  LVLLOC0040: " 国家",

  LVLLOC0041: " 编辑状态",

  LVLLOC0042: " 新增城市",
  LVLLOC0043: " 状态",

  LVLLOC0044: " 编辑城市",

  LVLLOC0045: " 新增城市时区",

  LVLLOC0046: " 编辑城市时区",

  LVLLOC0047: " 新增门店类型",

  LVLLOC0048: " 编辑门店类型",

  LVLLOC0049: "国家",
  LVLLOC0050: "省份/州",
  LVLLOC0051: "城市",
  LVLLOC0052: "城市时区",
  LVLLOC0053: "门店类型",
  LVLLOC0054: "查看国家",
  LVLLOC0055: "创建",
  LVLLOC0056: "查看状态",
  LVLLOC0057: "查看城市",
  LVLLOC0058: "查看城市时区",
  LVLLOC0059: "查看门店类型",

  LVLDV0001: "设备编号",
  LVLDV0002: "虚拟名称",
  LVLDV0003: "设备型号",
  LVLDV0004: "设备类型",
  LVLDV0005: "门店",
  LVLDV0006: "状态",
  LVLDV0007: "操作",
  LVLDV0008: "设备列表",

  LVLDV0009: "请阅读说明并勾选“添加设备”复选框。",
  LVLDV0010: "绑定门店设备",
  LVLDV0011: "设备网络配置步骤",
  LVLDV0012: "第一步:通电并打开设备",
  LVLDV0013: "如果设备有网络，它会自动连接到网络并开始工作。",
  LVLDV0014: "第二步: 配置网络",
  LVLDV0015:
    "您可以使用蓝牙来配置网络。或者，有些设备的触摸屏上内置了在固件中进行网络设置的功能。",
  LVLDV0016: "第三步: 恢复出厂设置",
  LVLDV0017: "某些设备无法进行网络设置。您可以尝试重置设备出厂设置。",
  LVLDV0018: "我已阅读说明。",
  LVLDV0019: "继续",

  LVLDV0020: "此字段是必填的",
  LVLDV0021: "设备绑定成功",
  LVLDV0022: "请选择您的时区",
  LVLDV0023: "新增设备到门店",
  LVLDV0024: "注册设备",
  LVLDV0025: "开机并设置网络",
  LVLDV0026: "1. 如果设备支持以太网功能，请插入网络线。",
  LVLDV0027:
    "2. 访问您的设备的以太网设置/无线网络设置菜单以打开通信设置页面。网络配置完成后，设备会在待机页面显示二维码。",
  LVLDV0028: "3. 在设备盒侧面或设备背后找到设备序列号。",
  LVLDV0029: "4. 将设备序列号填入系统中。",
  LVLDV0030: "请输入设备序列号",
  LVLDV0031: "将设备绑定到门店",
  LVLDV0032: "绑定时区",
  LVLDV0033: "门店",
  LVLDV0034: "绑定时区",
  LVLDV0035: "设备序列号",
  LVLDV0036: "新增",
  LVLDV0037: "请将设备指定给零售客户端或经销店.",
  LVLDV0038: "此设备将同步与出口相同的时区.",
  LVLDV0039: "设备详情",
  LVLDV0040: "解绑设备",
  LVLDV0041: "重新启动",
  LVLDV0042: "固件升级",
  LVLDV0043: "客户",
  LVLDV0044: "门店时区",
  LVLDV0045: "绑定",
  LVLDV0046: "设备绑定成功",
  LVLDV0047: "查看设备详细",
  LVLDV0048: "锁定时间",
  LVLDV0049: "锁定类型",
  LVLDV0050: "锁定码",
  LVLDV0051: "启动",
  LVLDV0052: "设备锁定",
  LVLDV0053: "设备名称",
  LVLDV0054: "序列号",
  LVLDV0055: "门店 ID",
  LVLDV0056: "客户名称",
  LVLDV0057: "设备解绑成功",
  LVLDV0058: "您确定要解除所选设备的绑定吗?",
  LVLDV0059: "设备",
  LVLDV0060: "自动捕获:",
  LVLDV0061: "位置",
  LVLDV0062: "识别时间",
  LVLDV0063: "商品名称",

  LVLDV0064: "条形码",
  LVLDV0065: "分类",
  LVLDV0066: "注册图",
  LVLDV0067: "设备场景",
  LVLDV0068: "设备 ID",
  LVLDV0069: "拖动“设置交叉线和方向”",
  LVLDV0070: "商品名称",
  LVLDV0071: "条形码",
  LVLDV0072: "分类1",
  LVLDV0073: "分类2",
  LVLDV0074: "商品条码",
  LVLDV0075: "分类1",
  LVLDV0076: "分类2",

  LVLDV0077: "序列号",
  LVLDV0078: "请输入设备序列号...",
  LVLDV0079: "所属账号",
  LVLDV0080: "设备ID",
  LVLDV0081: "编辑设备详情",
  LVLDV0082: "设备ID",
  LVLDV0083: "序列号",
  LVLDV0084: "设备别名",
  LVLDV0085: "设备型号",
  LVLDV0086: "设备类型",
  LVLDV0087: "客户名称",
  LVLDV0088: "门店",
  LVLDV0089: "门店ID",
  LVLDV0090: "状态",
  LVLDV0091: "在线",
  LVLDV0092: "离线",

  LVLDV0093: "分钟",

  LVLDV0094: "设备序列号或者标识ID",
  LVLDV0095: "输入设备序列号或者标识",
  LVLDV0096: "确定",
  LVLDV0097: "绑定设备到一个广告商或者零售商",
  LVLDV0098: "请指定设备到一个地点和区域.",
  LVLDV0099: "此设备将同步地点的时区.",
  LVLDV0100: "此设备将同步时区",
  LVLDV0101: "地区",
  LVLDV0102: "该字段为必填项",
  LVLDV0103: "设置成功！",
  LVLDV0104: "数据加载成功",
  LVLDV0105: "设备没有可用通道。",
  LVLDV0106: "未处理的响应代码",
  LVLDV0108: "通道配置",
  LVLDV0109: "设备标识",

  RTPD0001: "零售商品",
  RTPD0002: "新增商品",
  RTPD0003: "修改商品",
  RTPD0004: "商品名称",
  RTPD0005: "商品ID",
  RTPD0006: "商品条码",
  RTPD0007: "API 管理",
  RTPD0008: "API 新增",
  RTPD0009: "API 修改",
  RTPD00010: "捕获成功。",
  RTPD00011: "捕获失败。",
  RTPD00013: "正在捕获。",
  RTPD00014: "商品图片",
  RTMT0001: "元数据",
  RTMT0002: "通道名称",
  RTMT0003: "算法",
  RTMT0004: "事件时间",
  RTMT0005: "注册图",
  RTMT0006: "事件图",
  RTMT0007: "识别目标",
  RTMT0008: "事件数据",
  RTMT0009: "货架监控算法",
  RTMT0010: "元数据列表",
  RTMT0011: "人群分析事件",
  RTMT0012: "货架分析事件",
  RTMT0013: "是否为子设备",
  RTMT0014: "商户类型",
  RTMT0015: "年龄",
  RTMT0016: "性别",
  RTMT0017: "眼镜",
  RTMT0018: "帽子",
  RTMT0019: "面部状态",
  RTMT0020: "用户识别ID",
  RTMT0021: "通道序号",
  RTMT0022: "下身着装颜色",
  RTMT0023: "是否骑车",
  RTMT0024: "是否打伞",
  RTMT0025: "上身着装颜色",
  RTMT0026: "高度",
  RTMT0027: "事件类型",
  RTMT0028: "背包",
  RTMT0029: "类型名称",
  RTMT0030: "产品名称",
  RTMT0032: "场景",
  RTMT0033: "主设备",
  RTMT0034: "子设备",
  RTMT0035: "其他设备",

  PLACE01: "请输入名称",
  PLACE02: "是否导出图片?",
  PLACE03: "机构简介图片",
  PLACE04: "电子邮箱地址已经存在于同一或其他公司",
  PLACE05: "电子邮箱已经存在",
  PLACE06: "添加成功!",
  PLACE07: "正在保存...",
  PLACE08: "修改成功!",
  PLACE09: "确认删除！",
  PLACE010: "您确定要永久删除此零售产品吗?",
  PLACE011:
    "系统将发送一个链接，以重置您的密码到您使用的电子邮箱账户，请仔细核对。",
  PLACE012: "请输入您的电子邮箱.",
  PLACE014:
    "此帐户是您组织的管理帐户，可以在应用程序中创建您的合作伙伴或操作员。",
  PLACE013: "没有账户?",
  PLACE015: "以流动电话登记",
  PLACE016: "以电邮登记",
  PLACE017: "请输入设备序列号",
  PLACE018: "记录删除成功。",
  PLACE019: "记录创建成功。",
  PLACE020: "记录修改成功。",
  PLACE021: "门店创建成功。",
  PLACE022: "门店修改成功。",
  PLACE023: "门店删除成功。",
  PLACE024: "注:子设备为可独立工作的摄像头，无需使用算法盒。",

  LOCATION10: "门店编码",
  LOCATION11: "区域名称",
  LOCATION12: "区域编码",
  LOCATION13: "同步",

  MENU01: "看板",
  MENU02: "基础客流统计",
  MENU03: "货架监测摘要",
  MENU04: "人口统计汇总",
  MENU05: "人数统计",
  MENU06: "货架监测",
  MENU07: "群体",
  MENU08: "零售商管理",
  MENU09: "零售门店管理",
  MENU10: "设备管理",
  MENU11: "零售商品",
  MENU12: "元数据",
  MENU13: "广告商管理",
  MENU14: "权限管理",
  MENU16: "精准客流分析",
  MATADATA01: "导出",
  MATADATA02: "是",
  MATADATA03: "否",
  MATADATA04: "查看",
  MATADATA05: "请选择日期",
  MATADATA06: "开始日期",
  MATADATA07: "结束日期",
  MATADATA08: "",
  MATADATA09: "",

  DSPC001: "客户统计算法",
  DSPC002: "区域类型",
  DSPC003: "线",
  DSPC004: "多边形",

  DSKU001: "编辑检测SKU",
  DSKU002: "位置",
  DSKU003: "识别时间",
  DSKU004: "产品名称",
  DSKU005: "条形码",
  DSKU006: "类别",
  DSKU007: "已注册的照片",

  PCS01: "基础客流统计",
  PCS02: "欢迎回来",
  PCS03: "周期",
  PCS04: "对比",
  PCS05: "上个周期",
  PCS06: "开始日期",
  PCS07: "结束日期",
  PCS08: "门店",
  PCS09: "所有门店",
  PCS10: "应用",
  PCS11: "总访客",
  PCS12: "进店流量趋势",
  PCS13: "总变化",
  PCS14: "最高进店数",
  PCS15: "进店容量",
  PCS16: "上个周期",
  PCS17: "增长率",
  PCS18: "转化率",
  PCS19: "转化率",
  PCS20: "上个周期",
  PCS21: "周期",
  PCS22: "转化率",
  PCS23: "上个周期",
  PCS24: "变化",
  PCS25: "请选择一个时间周期。",
  PCS26: "周",
  PCS27: "月",
  PCS28: "请选择一个门店.",
  PCS29: "门店名称",
  PCS30: "所有门店",
  PCS31: "顾客",
  PCS32: "天",

  PCS33: "附加信息",
  PCS34: "产品数量",
  PCS35: "缺货",
  PCS36: "当天日期是不可选择的.",
  PCS37: "周期",
  PCS38: "应用",
  PCS39: "图片总数",
  PCS40: "货架监控",
  PCS41: "品牌",
  PCS42: "匹配频率",
  PCS43: "总计",
  PCS44: "摆错",
  PCS45: "缺货",
  PCS46: "精度",
  PCS48: "注册图",
  PCS49: "实时图",
  PCS50: "线位置",
  PCS51: "准确率",
  PCS52: "最高",
  PCS53: "准确率品牌",
  PCS54: "最低",
  PCS55: "性别分布",
  PCS56: "年龄分布",
  PCS57: "访客人口分布",
  PCS58: "按时间划分的最高访问量",
  PCS59: "访客",
  PCS60: "按时间排序的最少访客",
  PCS61: "按人口统计划分的最高访客人数",
  PCS62: "男性",
  PCS63: "按人口统计的最少访客",
  PCS64: "总访客",
  PCS65: "时间",
  PCS66: "2小时",
  PCS67: "4小时",
  PCS68: "6小时",
  PCS69: "年龄",
  PCS70: "女性",
  PCS71: "店内",
  PCS72: "店外",
  PCS73: "套餐",
  PCS74: "权限级别",
  PCS75: "输入授权级别名称",
  PCS76: "隐藏密钥",
  PCS77: "显示密钥",
  PCS78: "请选择权限级别。",
  PCS79: "请选择一个角色。",
  PCS80: "请选择门店。",
  PCS81: "复制成功",
  PCS82: "负责门店",
  PCS83: "是否删除当前角色?",
  PCS84: "新增成功",
  PCS85: "角色名称不能为空。",
  PCS86: "修改成功",
  PCS87: "无绑定的门店",
  PCS88: "请输入商品名称.",
  PCS89: "请重置识别区域.",
  PCS90: "请输入设备SN编号.",
  PCS91: "请选择导出时间段。",
  PCS92: "正在加载...,请稍后",
  PCS93: "请选择角色.",
  PCS94: "请输入您的密码。",
  PCS95: "请选择零售商",
  PCS96: "上传图片",
  PCS97: "当前设备没有可用频道。",
  PCS98: "获取商品图片",
  PCS99: "识别商品图片",
  PCS100: "重置识别区域",
  PCS101: "预览商品",
  PCS102: "每页行数 :",
  PCS103: "正在加载......",
  PCS104: "保存成功",
  PCS105: "没有数据",
  PCS106: "选择行",
  PCS107: "选择行",
  PCS108: "我已阅读并同意",
  PCS109: "《数据处理协议》",
  PCS110: "《隐私协议》",
  PCS112: "请勾选并阅读相关协议",
  PCS113: "和",
  PCS114: "请选择门店...",
  PCS115: "空模板",
  PCS116: "请选择商品模板是否为空.",
  PCS117: "没有查询到设备.",
  PCS118: "商品模板是否为空?",
  PCS119: "请输入门店名称",
  PCS120: "订阅截至时间不能为空。",
  PCS121: "订阅",
  PCS122: "订阅开始时间不能为空。",
  PCS123: "截至时间不能早于开始时间",
  PCS124: "没有功能权限,请联系管理员",
  PCS125: "请输入姓氏",
  PCS126: "请输入名字",
  PCS127: "请输入用户ID",
  PCS128: "请选择加入日期",
  PCS129: "请输入您的PIN",
  PCS130: "请输入确认密码",
  PCS131: "两次密码输入不一致",
  PCS132: "订阅记录",
  PCS133: "成人",
  PCS134: "儿童",
  PCS135: "成人 & 儿童",
  PCS136: "上个周期",
  PCS137: "成人比率",
  PCS138: "儿童比率",
  PCS139: "经过",
  PCS140: "进入",
  PCS141: "离开",
  PCS142: "进入率",
  PCS143: "上个周期",
  PCS144: "",
  PCS145: "",
  PCS146: "",
  PCS147: "",
  PCS148: "",
  PCS149: "",

  data: {
    private_agree: `个人信息保护及隐私政策`,
    //     private_agree_text: `<h1>
    //     个人信息保护及隐私政策
    // </h1>
    // <p>
    //     <span style="font-size: 16px;">
    //         本政策仅适用于
    //     </span>
    //     <span style="font-size: 16px;">
    //         ZATA
    //     </span>
    //     <span style="font-size: 16px;">
    //         产品或服务。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         最近更新日期：2024年10月。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         电子邮件：support@zkdigimax.com
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         本政策将帮助您了解以下内容：
    //     </span>
    // </p>
    // <ul>
    //     <li>
    //         <span style="font-size: 16px;">
    //             本政策将帮助您了解以下内容：
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             个人信息收集使用规则
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             我们如何保护您的个人信息
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             您的权利
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             我们如何处理未成年人的个人信息
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             本政策如何更新
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             如何联系我们
    //         </span>
    //     </li>
    // </ul>
    // <p style="text-indent: 2em;">
    //     <span style="font-size: 16px;">
    //         厦门熵基科技有限公司及其关联公司（下称“熵基科技”、“本公司”或“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时，熵基科技承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
    //     </span>
    // </p>
    // <p style="text-indent: 2em;">
    //     <span style="font-size: 16px;">
    //         在使用本产品（或服务）前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用本产品或服务，即表示您已充分理解并同意本政策。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         一、个人信息收集使用规则
    //     </strong>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         （一）我们收集哪些您的个人信息
    //     </strong>
    // </p>
    // <p style="text-align: left;">
    //     <span style="color: rgb(0, 0, 0); font-size: 16px;">
    //         1、您使用ZATA（软件产品名称），我们将收集您的以下信息：
    //     </span>
    // </p>
    // <p style="text-indent: 2em;">
    //     <span style="color: rgb(0, 0, 0); font-size: 16px;">
    //         我们提供的业务功能需要依赖部分信息才得以运行。您选择使用该项业务功能，则需要向我们提供或允许我们收集的必要信息包括：用户名（姓名/昵称/别名/代码）、电话号码、邮箱。
    //     </span>
    // </p>
    // <p style="text-indent: 2em;">
    //     <span style="font-size: 16px;">
    //     </span>
    // </p>
    // <p style="text-indent: 2em;">
    //     <span style="font-size: 16px;">
    //         根据您选择的软件产品，您可选择性录入手机号/姓名，在账号登录需要使用邮箱作为账号，您必须提供真实邮箱才能使用相关业务
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p style="text-indent: 2em;">
    //     <span style="font-size: 16px;">
    //         当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
    //     </span>
    // </p>
    // <p>
    //     <strong>
    //         （二）我们如何使用您的个人信息
    //     </strong>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         1、对于必要的个人信息，我们会用来提供该项业务功能，我们也会使用以下信息来维护和改进本项业务功能，开发新的业务功能等。
    //     </span>
    // </p>
    // <table style="width: auto;">
    //     <tbody>
    //         <tr>
    //             <td colSpan="1" rowSpan="1" width="182">
    //                 信息类型
    //             </td>
    //             <td colSpan="1" rowSpan="1" width="287">
    //                 使用目的
    //             </td>
    //             <td colSpan="1" rowSpan="1" width="245">
    //                 保存期限
    //             </td>
    //         </tr>
    //         <tr>
    //             <td colSpan="1" rowSpan="1" width="182">
    //                 用户名（姓名/姓氏/）、电话号码、邮箱、职位、公司名称
    //             </td>
    //             <td colSpan="1" rowSpan="1" width="287">
    //                 用于个人账号的发放管理，包括个人标记、安全追溯、基本维护
    //             </td>
    //             <td colSpan="1" rowSpan="1" width="245">
    //                 我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非经过您的同意延长保留期或受到法律的允许。
    //             </td>
    //         </tr>
    //         <tr>
    //             <td colSpan="1" rowSpan="1" width="182">
    //                 门店地址，门店名称
    //             </td>
    //             <td colSpan="1" rowSpan="1" width="287">
    //                 用于门店管理，包括门店的查询，基本维护
    //             </td>
    //             <td colSpan="1" rowSpan="1" width="245">
    //                 我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非经过您的同意延长保留期或受到法律的允许。
    //             </td>
    //         </tr>
    //     </tbody>
    // </table>
    // <p style="text-align: justify;">
    //     <strong>
    //         （三）我们如何使用Cookie/Token和同类技术
    //     </strong>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         1、Cookie
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         Cookie和同类技术是互联网中普遍使用的技术。为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。我们不会将Cookie用于本政策所述目的之外的任何目的。您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
    //     </span>
    // </p>
    // <p style="text-align: left;">
    //     <span style="font-size: 16px;">
    //         2、Token
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         我们或接入我们产品或服务的第三方服务提供者，可能通过放置安全的Token及相关技术收集您的信息，目的是为您提供更个性化的用户体验和服务。您可通过系统设置来对此进行管理。具体来说：
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         (1)Token是支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制。当您使用本产品或服务时，我们会在您的浏览器上发送一个或多个名为Token的小数据文件，
    //         用来简化您重复登录的步骤、帮助判断您的登录状态以及账号或数据安全。指定给您的Token是唯一的，它只能被将Token发布给您的域中的Web服务器读取。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         (2)我们承诺，对Token及相关技术所收集信息的研究仅用于提升服务/产品质量及优化用户体验之目的，我们不会将其用于本政策所述目的之外的任何用途。
    //     </span>
    // </p>
    // <p style="text-align: left;">
    //     <span style="font-size: 16px;">
    //         3、其他同类技术
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         除Cookie外，我们还会在网站上使用网站信标、像素标签等其他同类技术，帮助我们了解您的产品或服务偏好并改善客户服务。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         （四）我们如何共享、转让、公开披露您的个人信息
    //     </strong>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         1、共享
    //     </strong>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，除非获得您的明确同意。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         2、转让
    //     </strong>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         a) 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         b) 在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         3、公开披露
    //     </strong>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         我们仅会在以下情况下，公开披露您的个人信息：
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         a获得您明确同意后；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         b) 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，包括：
    //     </span>
    // </p>
    // <ul>
    //     <li>
    //         <span style="font-size: 16px;">
    //             与个人信息控制者履行法律法规规定的义务相关的；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             与国家安全、国防安全直接相关的；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             与公共安全、公共卫生、重大公共利益直接相关的；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             与刑事侦查、起诉、审判和判决执行等直接相关的；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             所涉及的个人信息是个人信息主体自行向社会公众公开的；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             根据个人信息主体要求签订和履行合同所必需的；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；
    //         </span>
    //     </li>
    //     <li>
    //         <span style="font-size: 16px;">
    //             个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要， 且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。
    //         </span>
    //     </li>
    // </ul>
    // <p>
    //     <br>
    // </p>
    // <p style="text-indent: 2em;">
    //     <span style="font-size: 16px;">
    //         请您注意，根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         二、我们如何保护您的个人信息
    //     </strong>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         （一）我们重视个人数据的安全。我们采用适当的物理、管理和技术保障措施来保护您的个人数据不被未经授权访问、披露、使用、修改、损坏或丢失。例如，我们会使用加密技术确保数据的机密性；我们会使用保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人数据；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人数据重要性的认识。我们会尽力保护您的个人数据，但是请注意任何安全措施都无法做到无懈可击。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         （二）我们将会在达成本政策所述目的所需的期限内保留您的个人数据，除非按照法律要求或许可需要延长保留期或受到法律的允许。因为基于不同的场景和产品及服务的不同，数据的存储期可能会有所不同，我们用于确定存留期的标准包括：完成该业务目的需要留存个人数据的时间，包括提供产品和服务，维护相应的交易及业务记录，管控并提升产品与服务性能与质量，保证系统、产品和服务的安全，应对可能的用户查询或投诉，问题定位等；用户是否同意更长的留存期间；法律、合同等是否有保留数据的特殊要求等。只要您的账户是为您提供服务必须，我们都将保留您的注册信息。您也可以选择注销您的账号，在您注销账号后，我们会停止基于该账号提供产品和服务，并在无特殊法律要求的情况下，删除您相应的个人数据。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         （三）在不幸发生个人信息安全事件后，我们将按照法律法规的要求（最迟不超过30个自然日内）向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         （四）互联网环境并非百分之百安全，尽管我们有这些安全措施，但请注意在互联网上不存在“完善的安全措施”，我们将尽力确保您的信息的安全性。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         （五）为确保流畅的浏览体验，您可能会收到来自我们及其合作伙伴外部的第三方（下文简称“第三方”）提供的内容或网络链接。我们对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容、产品和服务。我们无法控制第三方的隐私和数据保护政策，此类第三方不受到本政策的约束。在向第三方提交个人信息之前，请参见该第三方的隐私保护政策。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         三、您的权利
    //     </strong>
    // </p>
    // <p style="text-align: justify;">
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
    //     </span>
    // </p>
    // <p>
    //     <strong>
    //         （一）访问您的个人信息
    //     </strong>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
    //     </span>
    //     <span style="font-size: 16px;">
    //         ZATA软件
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         如果您无法通过上述
    //     </span>
    //     <span style="font-size: 16px;">
    //         方式
    //     </span>
    //     <span style="font-size: 16px;">
    //         访问这些个人信息，您可以随时发送电子邮件至
    //     </span>
    //     <span style="font-size: 16px;">
    //         support@zkdigimax.com
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请发送电子邮件至
    //     </span>
    //     <span style="font-size: 16px;">
    //         support@zkdigimax.com
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         （二）更正您的个人信息
    //     </strong>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         如果您无法通过上述
    //     </span>
    //     <span style="font-size: 16px;">
    //         方式
    //     </span>
    //     <span style="font-size: 16px;">
    //         更正这些个人信息，您可以随时发送电子邮件至
    //     </span>
    //     <span style="font-size: 16px;">
    //         support@zkdigimax.com
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         （三）删除您的个人信息
    //     </strong>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         在以下情形中，您可以向我们书面提出删除个人信息的请求：
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         1、如果我们处理个人信息的行为违反法律法规；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         2、如果我们收集、使用您的个人信息，却未征得您的同意；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         3、如果我们处理个人信息的行为违反了与您的约定；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         4、如果您不再使用我们的产品或服务，或您注销了账号；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         5、如果我们不再为您提供产品或服务。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         当适用的法律要求的情况下，当我们基于您的同意处理您的个人数据时，您还有权随时撤销您的同意。但撤销同意不会影响撤销前我们基于您的同意处理您个人数据的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人数据。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         （四）响应您的上述请求
    //     </strong>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         我们将在
    //     </span>
    //     <span style="font-size: 16px;">
    //         三十天
    //     </span>
    //     <span style="font-size: 16px;">
    //         内作出答复。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         在以下情形中，我们将无法响应您的请求：
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         1、与个人信息控制者履行法律法规规定的义务相关的；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         2、与国家安全、国防安全直接相关的；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         3、与公共安全、公共卫生、重大公共利益直接相关的；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         4、与刑事侦查、起诉、审判和执行判决等直接相关的；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         5、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         7、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         8、涉及商业秘密的。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         四、我们如何处理未成年人的个人信息
    //     </strong>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，未成年人不应创建自己的个人信息主体账户。如您为未成年人的，建议您请您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         对于经父母同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         五、本政策如何更新
    //     </strong>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         我们的个人信息保护及隐私政策可能变更。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明个人信息保护政策的具体变更内容）。
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         本政策所指的重大变更包括但不限于：
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         3、个人信息共享、转让或公开披露的主要对象发生变化；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
    //     </span>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <strong>
    //         六、如何联系我们
    //     </strong>
    // </p>
    // <p>
    //     <br>
    // </p>
    // <p>
    //     <span style="font-size: 16px;">
    //         如果您对本个人信息保护及隐私政策有任何疑问、意见或建议，请通过以下方式与我们联系将您的问题发送至
    //     </span>
    //     <span style="font-size: 16px;">
    //         support@zkdigimax.com
    //     </span>
    //     <span style="font-size: 16px;">
    //         。一般情况下，我们将在三十天内回复。更多联系方式会在我们的官方网站
    //     </span>
    //     <span style="font-size: 16px;">
    //         （https://www.zkdigimax.com/）
    //     </span>
    //     <span style="font-size: 16px;">
    //         上公告。
    //     </span>
    // </p>
    // <p>
    //     <br>
    // </p>`,

    private_agree_text: `
  <h1>个人信息保护及隐私政策</h1>
    <p>
      <span style="font-size: 16px"> 本政策仅适用于 </span>
      <span style="font-size: 16px"> ZATA </span>
      <span style="font-size: 16px"> 产品或服务。 </span>
    </p>
    <p>
      <span style="font-size: 16px"> 最近更新日期：2024年10月。 </span>
    </p>
    <p>
      <span style="font-size: 16px">
        如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：
      </span>
    </p>
    <p>
      <span style="font-size: 16px"> 电子邮件：support@zkdigimax.com </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px"> 本政策将帮助您了解以下内容： </span>
    </p>

    <ul style="text-indent: 2em">
      <li>
        <span style="font-size: 16px"> 本政策将帮助您了解以下内容： </span>
      </li>
      <li>
        <span style="font-size: 16px"> 个人信息收集使用规则 </span>
      </li>
      <li>
        <span style="font-size: 16px"> 我们如何保护您的个人信息 </span>
      </li>
      <li>
        <span style="font-size: 16px"> 您的权利 </span>
      </li>
      <li>
        <span style="font-size: 16px"> 我们如何处理未成年人的个人信息 </span>
      </li>
      <li>
        <span style="font-size: 16px"> 本政策如何更新 </span>
      </li>
      <li>
        <span style="font-size: 16px"> 如何联系我们 </span>
      </li>
    </ul>

    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        厦门熵基科技有限公司及其关联公司（下称“熵基科技”、“本公司”或“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时，熵基科技承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        在使用本产品（或服务）前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用本产品或服务，即表示您已充分理解并同意本政策。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> 一、个人信息收集使用规则 </strong>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> （一）我们收集哪些您的个人信息 </strong>
    </p>
    <p style="text-align: left">
      <span style="color: rgb(0, 0, 0); font-size: 16px">
        1、您使用ZATA（软件产品名称），我们将收集您的以下信息：
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="color: rgb(0, 0, 0); font-size: 16px">
        我们提供的业务功能需要依赖部分信息才得以运行。您选择使用该项业务功能，则需要向我们提供或允许我们收集的必要信息包括：用户名（姓名/昵称/别名/代码）、电话号码、邮箱。
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px"> </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        根据您选择的软件产品，您可选择性录入手机号/姓名，在账号登录需要使用邮箱作为账号，您必须提供真实邮箱才能使用相关业务
      </span>
    </p>

    <p style="text-align: left">
      <span style="color: rgb(0, 0, 0); font-size: 16px">
        2、您可自主选择向我们提供或允许我们收集下列信息：个人照片、居住地址、公司、IP地址信息、MAC地址信息、访问日期和时间。
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="color: rgb(0, 0, 0); font-size: 16px">
        这些信息并非该业务功能运行所必需，但这些信息对改善服务质量、研发新产品或服务等有非常重要的意义。我们不会强制要求您提供这些信息，您如拒绝不会对使用该业务功能产生不利影响。
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px"> </span>
    </p>

    <p style="text-align: left">
      <span style="color: rgb(0, 0, 0); font-size: 16px">
        3、在您使用该业务功能时，我们的软件会向您申请下列与个人信息相关的系统权限：获取定位。
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="color: rgb(0, 0, 0); font-size: 16px">
        如果您不授权，将会导致我们无法提供该业务功能。除上述权限之外，您可自主选择是否额外授予软件其他的系统权限。
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px"> </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
      </span>
    </p>

    <p>
      <strong> （二）我们如何使用您的个人信息 </strong>
    </p>
    <p>
      <span style="font-size: 16px">
        1、对于必要的个人信息，我们会用来提供该项业务功能，我们也会使用以下信息来维护和改进本项业务功能，开发新的业务功能等。
      </span>
    </p>
    <table style="width: auto">
      <tbody>
        <tr>
          <td colspan="1" rowspan="1" width="182">信息类型</td>
          <td colspan="1" rowspan="1" width="287">使用目的</td>
          <td colspan="1" rowspan="1" width="245">保存期限</td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1" width="182">
            用户名（姓名/昵称/别名/代码）、电话号码、邮箱
          </td>
          <td colspan="1" rowspan="1" width="287">记录账号信息</td>
          <td colspan="1" rowspan="1" width="245">永久（账户注销即删除）</td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        2、对于非必要的个人信息，我们会用于以下用途：
      </span>
    </p>
    <table style="width: auto">
      <tbody>
        <tr>
          <td colspan="1" rowspan="1" width="182">信息类型</td>
          <td colspan="1" rowspan="1" width="287">使用目的</td>
          <td colspan="1" rowspan="1" width="245">保存期限</td>
        </tr>
        <tr>
          <td colspan="1" rowspan="1" width="182">个人照片</td>
          <td colspan="1" rowspan="1" width="287">记录账号信息</td>
          <td colspan="1" rowspan="1" width="245">永久（账户注销即删除）</td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
    </p>
    <p style="text-align: justify">
      <strong> （三）我们如何使用Cookie/Token和同类技术 </strong>
    </p>
    <p>
      <span style="font-size: 16px"> 1、Cookie </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        Cookie和同类技术是互联网中普遍使用的技术。为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。我们不会将Cookie用于本政策所述目的之外的任何目的。您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
      </span>
    </p>
    <p style="text-align: left">
      <span style="font-size: 16px"> 2、其他同类技术 </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        除Cookie外，我们还会在网站上使用网站信标、像素标签等其他同类技术，帮助我们了解您的产品或服务偏好并改善客户服务。
      </span>
    </p>
    <p>
      <br />
    </p>

    <p>
      <strong> （四）云平台安全责任 </strong>
    </p>
    <p>
      <strong
        >1、熵基负责熵基云平台上的服务和数据交互的安全管理和运营，对提供的云服务平台和基础架构的安全性负责。
      </strong>
    </p>
    <p style="text-indent: 2em">
      <strong>
        客户通过调用API接口、SDK自行开发的软件或硬件嵌入式软件，接入熵基云平台需要客户自己保障其应用及数据，包括硬件设备、软件的安全合规。其中熵基云的主要责任是研发并运维各项基础服务、平台服务和应用服务，而客户的主要责任是在使用熵基云服务之上定制开发的第三方客户端和搭建的第三方云服务等。下图为基础云服务提供商、熵基以及客户信息安全责任共同承担责任模型。
      </strong>
    </p>

    <p>
      <br />
    </p>
    <p>
      <strong> 2、熵基云的安全责任 </strong>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        a)
        熵基云通过选择全球知名的云主机服务商亚马逊、腾讯云、阿里云等一流云计算平台，确保安全管理和运营的基础设施，物理设备的安全。
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        b)
        熵基云安全覆盖数据安全和云服务安全。熵基承诺利用其安全团队以及全球范围知名的安全服务厂商的专业攻击防护技术经验，提供云平台的安全运维和运营服务，切实保护熵基云的安全运营，以及保障客户、用户隐私和数据的安全。包括但不限于：
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        数据安全：指客户在云业务环境中的业务数据自身的安全管理，包括收集与识别、分类与分级、权限与加密以及隐私合规等方面；
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        访问控制管理：对资源和数据的访问权限管理，包括用户管理、权限管理、身份验证等；
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        云服务安全：指在云计算环境下的业务相关应用系统的安全管理，包括应用和服务接口的设计、开发、发布、配置和使用等方面。
      </span>
    </p>

    <p>
      <br />
    </p>
    <p>
      <strong> 3、客户的安全责任 </strong>
    </p>

    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        a)
        客户在使用熵基云的解决方案的时候，需要严格按照熵基的安全配置和接入要求执行。同时客户需要保证自己的云端、客户端或者硬件产品本身的安全性。
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        b)
        基于熵基SDK/API开发的应用软件，熵基仅提供技术支持，但是无法为该软件整体提供安全保障。
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        c)
        对于基于熵基方案的定制产品的数据安全合规、隐私政策等相关信息，隐私政策声明以及法律合规性由客户负责，必要时候，熵基安全合规团队愿意提供安全解决方案的帮助和咨询服务。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> （五）我们如何共享、转让、公开披露您的个人信息 </strong>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong>1、共享 </strong>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，除非获得您的明确同意。
        我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> 2、转让 </strong>
    </p>

    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        a)
        在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
      </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        b)
        在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </span>
    </p>

    <p>
      <br />
    </p>
    <p>
      <strong> 3、公开披露 </strong>
    </p>
    <p>
      <strong>我们仅会在以下情况下，公开披露您的个人信息： </strong>
    </p>

    <p style="text-indent: 2em">
      <span style="font-size: 16px"> c) 获得您明确同意后； </span>
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        d)
        基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，
      </span>
    </p>

    <p>
      <span style="font-size: 16px"> 包括： </span>
    </p>
    <ul style="text-indent: 2em">
      <li>
        <span style="font-size: 16px">
          与个人信息控制者履行法律法规规定的义务相关的；
        </span>
      </li>
      <li>
        <span style="font-size: 16px"> 与国家安全、国防安全直接相关的； </span>
      </li>
      <li>
        <span style="font-size: 16px">
          与公共安全、公共卫生、重大公共利益直接相关的；
        </span>
      </li>
      <li>
        <span style="font-size: 16px">
          与刑事侦查、起诉、审判和判决执行等直接相关的；
        </span>
      </li>
      <li>
        <span style="font-size: 16px">
          出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
        </span>
      </li>
      <li>
        <span style="font-size: 16px">
          所涉及的个人信息是个人信息主体自行向社会公众公开的；
        </span>
      </li>
      <li>
        <span style="font-size: 16px">
          根据个人信息主体要求签订和履行合同所必需的；
        </span>
      </li>
      <li>
        <span style="font-size: 16px">
          从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
        </span>
      </li>
      <li>
        <span style="font-size: 16px">
          维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
        </span>
      </li>
      <li>
        <span style="font-size: 16px">
          个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；
        </span>
      </li>
      <li>
        <span style="font-size: 16px">
          个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，
          且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。
        </span>
      </li>
    </ul>
    <p>
      <br />
    </p>
    <p style="text-indent: 2em">
      <span style="font-size: 16px">
        请您注意，根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> 二、我们如何保护您的个人信息 </strong>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        （一）我们重视个人数据的安全。我们采用适当的物理、管理和技术保障措施来保护您的个人数据不被未经授权访问、披露、使用、修改、损坏或丢失。例如，我们会使用加密技术确保数据的机密性；我们会使用保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人数据；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人数据重要性的认识。我们会尽力保护您的个人数据，但是请注意任何安全措施都无法做到无懈可击。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        （二）我们将会在达成本政策所述目的所需的期限内保留您的个人数据，除非按照法律要求或许可需要延长保留期或受到法律的允许。因为基于不同的场景和产品及服务的不同，数据的存储期可能会有所不同，我们用于确定存留期的标准包括：完成该业务目的需要留存个人数据的时间，包括提供产品和服务，维护相应的交易及业务记录，管控并提升产品与服务性能与质量，保证系统、产品和服务的安全，应对可能的用户查询或投诉，问题定位等；用户是否同意更长的留存期间；法律、合同等是否有保留数据的特殊要求等。只要您的账户是为您提供服务必须，我们都将保留您的注册信息。您也可以选择注销您的账号，在您注销账号后，我们会停止基于该账号提供产品和服务，并在无特殊法律要求的情况下，删除您相应的个人数据。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        （三）在不幸发生个人信息安全事件后，我们将按照法律法规的要求（最迟不超过30个自然日内）向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        （四）互联网环境并非百分之百安全，尽管我们有这些安全措施，但请注意在互联网上不存在“完善的安全措施”，我们将尽力确保您的信息的安全性。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        （五）为确保流畅的浏览体验，您可能会收到来自我们及其合作伙伴外部的第三方（下文简称“第三方”）提供的内容或网络链接。我们对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容、产品和服务。我们无法控制第三方的隐私和数据保护政策，此类第三方不受到本政策的约束。在向第三方提交个人信息之前，请参见该第三方的隐私保护政策。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> 三、您的权利 </strong>
    </p>
    <p style="text-align: justify">
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
      </span>
    </p>
    <p>
      <strong> （一）访问您的个人信息 </strong>
    </p>
    <p>
      <span style="font-size: 16px">
        您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
      </span>
      <span style="font-size: 16px"> ZATA软件 </span>
    </p>
    <p>
      <span style="font-size: 16px"> 如果您无法通过上述 </span>
      <span style="font-size: 16px"> 方式 </span>
      <span style="font-size: 16px">
        访问这些个人信息，您可以随时发送电子邮件至
      </span>
      <span style="font-size: 16px"> support@zkdigimax.com </span>
    </p>
    <p>
      <span style="font-size: 16px">
        对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请发送电子邮件至
      </span>
      <span style="font-size: 16px"> support@zkdigimax.com </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> （二）更正您的个人信息 </strong>
    </p>
    <p>
      <span style="font-size: 16px">
        当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
      </span>
    </p>
    <p>
      <span style="font-size: 16px"> 如果您无法通过上述 </span>
      <span style="font-size: 16px"> 方式 </span>
      <span style="font-size: 16px">
        更正这些个人信息，您可以随时发送电子邮件至
      </span>
      <span style="font-size: 16px"> support@zkdigimax.com </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> （三）删除您的个人信息 </strong>
    </p>
    <p>
      <span style="font-size: 16px">
        在以下情形中，您可以向我们书面提出删除个人信息的请求：
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        1、如果我们处理个人信息的行为违反法律法规；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        2、如果我们收集、使用您的个人信息，却未征得您的同意；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        3、如果我们处理个人信息的行为违反了与您的约定；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        4、如果您不再使用我们的产品或服务，或您注销了账号；
      </span>
    </p>
    <p>
      <span style="font-size: 16px"> 5、如果我们不再为您提供产品或服务。 </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        当适用的法律要求的情况下，当我们基于您的同意处理您的个人数据时，您还有权随时撤销您的同意。但撤销同意不会影响撤销前我们基于您的同意处理您个人数据的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人数据。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> （四）响应您的上述请求 </strong>
    </p>
    <p>
      <span style="font-size: 16px">
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
      </span>
    </p>
    <p>
      <span style="font-size: 16px"> 我们将在 </span>
      <span style="font-size: 16px"> 7天 </span>
      <span style="font-size: 16px">
        内作出答复 。如您不满意，还可以通过以下途径投诉：Support@zkdigimax.com
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        在以下情形中，我们将无法响应您的请求：
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        1、与个人信息控制者履行法律法规规定的义务相关的；
      </span>
    </p>
    <p>
      <span style="font-size: 16px"> 2、与国家安全、国防安全直接相关的； </span>
    </p>
    <p>
      <span style="font-size: 16px">
        3、与公共安全、公共卫生、重大公共利益直接相关的；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        4、与刑事侦查、起诉、审判和执行判决等直接相关的；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        5、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        7、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；
      </span>
    </p>
    <p>
      <span style="font-size: 16px"> 8、涉及商业秘密的。 </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> 四、我们如何处理未成年人的个人信息 </strong>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，未成年人不应创建自己的个人信息主体账户。如您为未成年人的，建议您请您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        对于经父母同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> 五、本政策如何更新 </strong>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        我们的个人信息保护及隐私政策可能变更。
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明个人信息保护政策的具体变更内容）。
      </span>
    </p>
    <p>
      <span style="font-size: 16px"> 本政策所指的重大变更包括但不限于： </span>
    </p>
    <p>
      <span style="font-size: 16px">
        1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        3、个人信息共享、转让或公开披露的主要对象发生变化；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
      </span>
    </p>
    <p>
      <span style="font-size: 16px">
        5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
      </span>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong> 六、如何联系我们 </strong>
    </p>
    <p>
      <br />
    </p>
    <p>
      <span style="font-size: 16px">
        如果您对本个人信息保护及隐私政策有任何疑问、意见或建议，请通过以下方式与我们联系将您的问题发送至
      </span>
      <span style="font-size: 16px"> support@zkdigimax.com </span>
      <span style="font-size: 16px">
        。一般情况下，我们将在三十天内回复。更多联系方式会在我们的官方网站
      </span>
      <span style="font-size: 16px"> （https://www.zkdigimax.com/） </span>
      <span style="font-size: 16px"> 上公告。 </span>
    </p>
    <p>
      <br />
    </p>
`,

    user_agree: "数据处理协议",
    user_agree_text: `<p style="text-align: center"><strong>数据处理协议</strong></p>
<p>
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
  >
  </span>
</p>
<p>
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >版本号: V2.0</span
  >
</p>
<p>
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >日期: 2024年10月</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
  >
  </span>
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >欢迎使用ZATA软件及服务!</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >熵基科技深知个人数据对您的重要性，并会尽全力保护您的个人数据安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人数据：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时，熵基科技承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人数据。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >请用户务必审慎阅读、充分理解各条款内容。除非用户已阅读并接受本协议所有条款，否则用户无权下载、安装或使用本软件及相关服务。用户的下载、安装、使用ZATA软件、登录账号等行为即视为用户已阅读并同意本协议的约束。</span
  >
</p>
<p>
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
  >
  </span>
</p>
<p><strong>【协议内容】</strong></p>
<p><strong>一、协议适用范围</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >本协议是用户与熵基科技之间关于用户下载、安装、使用本软件，以及使用熵基科技相关服务所订立的协议。“熵基科技”是指厦门熵基科技有限公司及其相关服务可能存在的运营关联单位，在本协议中称为“我们”。“用户”是指注册、登录、使用本软件及服务并获得管理权限的个人或组织，在本协议中称为“您”。</span
  >
</p>
<p><strong>二、数据处理范围</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >在我们向您提供相关服务过程中，会涉及个人数据收集，详见下表所列信息。</span
  >
</p>
<table style="width: auto">
  <tbody>
    <tr>
      <td colspan="1" rowspan="1" width="182">业务场景</td>
      <td colspan="1" rowspan="1" width="245">业务活动</td>
      <td colspan="1" rowspan="1" width="245">数据清单</td>
      <td colspan="1" rowspan="1" width="120">数据主体类别</td>
      <td colspan="1" rowspan="1" width="287">涉及的数据处理活动及使用目的</td>
    </tr>
    <tr>
      <td colspan="1" rowspan="1">我们提供远程技术支持给用户</td>
      <td colspan="1" rowspan="1">
        我们在软件实施、运维过程中可能会接触用户的个人数据
      </td>
      <td colspan="1" rowspan="1">
        用户名（姓名/昵称/别名/代码）、电话号码、邮箱、账号密码
      </td>
      <td colspan="1" rowspan="1">用户</td>
      <td colspan="1" rowspan="1">
        涉及到个人数据的存储、分析、删除；用于业务信息展示及分析，包括展示、更新、数据维护、个人标记、安全追溯、业务要求、研究、基本维护、账号管理
      </td>
    </tr>
  </tbody>
</table>
<p><strong>三、定义</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >除非双方另有约定，本协议中使用的术语定义按英文首字母顺序排列如下。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（一）“数据主体”（Data
    Subject），是指已确认身份或可确定身份的自然人。可确定身份的自然人是指可直接或间接确定身份的个人，特别是通过参考身份标识确定身份，身份标识包括姓名、身份证号、位置数据、在线身份标识或特定于该自然人的身体、生理、基因、精神、经济、文化或社会身份的一个或多个因素等。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（二）“欧盟标准合同条款”（EU Standard Contractual
    Clauses）是指自欧盟法案2016年第79号第44条起（且根据欧盟指令95/46/EC第26(2)条）规定的，向成立于无法确保充分数据保护的第三方国家/地区的处理方传输个人数据的相关标准合同条款，这些条款已在欧盟委员会相关网站上发布，可能随时修改或更新。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（三）“个人数据”（Personal
    Data）是指与已确认身份或可确定身份的自然人（“数据主体”）有关的任何信息。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（四）“个人数据泄露”（Personal Data
    Breach）是指按照本地数据保护法规规定，包括但不限于意外或非法销毁、丢失、更改、未经授权披露或访问传输、存储或其他方式处理的个人数据的安全违规行为。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（五）“数据保护法规”（Privacy
    Laws）是指与个人数据的安全和保护有关的任何可能适用的法律和/或条例（如GDPR或其他国家可适用的隐私保护法律），根据其实施或制定的任何法律和条例，以及这些法律和条例的修订、更新或重新颁布版本。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（六）“监管机构”（Regulatory
    Bodies）是指有权根据法令、规则、条例、行为准则或其他文件进行监管、调查或影响与数据安全和个人数据及隐私保护有关事宜的政府部门、监管机构、法定机构和其它机构。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（七）“服务”（Service）是指熵基科技根据与用户订立的具体服务协议及其他合同文本提供的涉及处理个人数据的服务。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（八）“子处理者”（Sub-processor），指熵基科技及相关团体以外的涉及个人信息处理的组织或个人。</span
  >
</p>
<p><strong>四、数据处理要求</strong></p>
<p><strong>（一）数据处理原则</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >我们按照“合法、正当、透明、目的限制、数据最小化、准确、存储期限最小化、完整性与保密性、可问责”的通用原则进行个人数据处理，我们将按照您的指示开展数据处理活动，不能超出您的指示以及本协议约定的目的和范围，不得将个人数据用于任何其他目的。我们认为您的指示违反适用的数据保护法规时，我们将立即通知您。我们将全力支持您保护数据主体的权利，如访问、更正、删除。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >我们将以云服务提供商提供的时钟源配置为准，若我们产品的服务方式/服务内容发生变更，将第一时间邮件周知您。</span
  >
</p>
<p><strong>（二）特殊类别的个人数据处理</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >我们不会以违反适用的数据保护法规的方式处理可能揭示种族或民族起源、政治观点，或行业工会成员资格的任何个人数据，唯一能识别自然人身份的生物数据。</span
  >
</p>
<p><strong>（三）个人数据跨境转移</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >不涉及个人数据跨境处理。</span
  >
</p>
<p><strong>（四）数据安全</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >在处理个人数据期间，我们根据适用的数据保护法规及您的要求采取有效的技术和组织措施，保证在数据处理过程中对可能接触的个人数据采取不低于自身信息保密程度的保密措施进行保密，未经您的书面许可，不得向任何第三方披露，保护个人数据免遭泄露。</span
  >
</p>
<p><strong>（五）数据泄露事故应急机制</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >1、在发现个人数据泄露事件后，我们会在七十二（72）小时内向您发送书面通知，以履行根据适用的数据保护法规规定通知监管机构和数据主体的义务。此类通知包含如下内容：</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（1）概述个人数据泄露的影响，包括但不限于描述个人数据泄露的性质、相关数据主体的类别和数量以及相关个人数据处理记录。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（2）个人数据泄露可能造成的风险和后果。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（3）我们为处理个人数据泄露而采取的措施或提出的建议。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（4）我们的数据保护官、隐私保护官及任何能提供详细信息的相关联系人的姓名和联系方式。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（5）您合理要求的与个人数据泄露相关的任何其他信息。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（6）如果上述信息不能在同一时间提供，则应在三十六（36）小时或者是在您要求的时间内分阶段提供。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >2、对于任何发生的个人数据泄露，我们将立即调查，识别、防止并降低影响，并在您事先同意的情况下，采取必要的补救措施。</span
  >
</p>
<p><strong>（六）子处理者引入</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >1、未经您的允许，我们不会将任何个人数据处理业务转包或外包给子处理者。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >2、根据适用的法律要求，我们应和子处理者签订数据处理协议并做尽职调查。我们将向子处理者传递您的要求，并对子处理者或其指定的任何第三方的数据处理行为导致的负面影响或不利后果承担责任。</span
  >
</p>
<p><strong>（七）个人数据的删除</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >除非有相反要求或数据保护法规另有规定，根据您的要求，一旦履行完双方之间适用的协议中约定的我们的义务，我们会立即删除相关个人数据，并向您证明其已经履行该义务。</span
  >
</p>
<p><strong>（八）云平台安全责任</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >1、熵基负责熵基云平台上的服务和数据交互的安全管理和运营，对提供的云服务平台和基础架构的安全性负责。客户通过调用API接口、SDK自行开发的软件或硬件嵌入式软件，接入熵基云平台需要客户自己保障其应用及数据，包括硬件设备、软件的安全合规。其中熵基云的主要责任是研发并运维各项基础服务、平台服务和应用服务，而客户的主要责任是在使用熵基云服务之上定制开发的第三方客户端和搭建的第三方云服务等。下图为基础云服务提供商、熵基以及客户信息安全责任共同承担责任模型。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >2、熵基云的安全责任</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（1）熵基云通过选择全球知名的云主机服务商亚马逊、腾讯云、阿里云等一流云计算平台，确保安全管理和运营的基础设施，物理设备的安全。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（2）熵基云安全覆盖数据安全和云服务安全。熵基承诺利用其安全团队以及全球范围知名的安全服务厂商的专业攻击防护技术经验，提供云平台的安全运维和运营服务，切实保护熵基云的安全运营，以及保障客户、用户隐私和数据的安全。包括但不限于：</span
  >
</p>
<p>
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >a.数据安全：指客户在云业务环境中的业务数据自身的安全管理，包括收集与识别、分类与分级、权限与加密以及隐私合规等方面；</span
  >
</p>
<p>
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >b.访问控制管理：对资源和数据的访问权限管理，包括用户管理、权限管理、身份验证等；</span
  >
</p>
<p>
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >c.云服务安全：指在云计算环境下的业务相关应用系统的安全管理，包括应用和服务接口的设计、开发、发布、配置和使用等方面。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >3、客户的安全责任</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（1）客户在使用熵基云的解决方案的时候，需要严格按照熵基的安全配置和接入要求执行。同时客户需要保证自己的云端、客户端或者硬件产品本身的安全性。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（2）基于熵基SDK/API开发的应用软件，熵基仅提供技术支持，但是无法为该软件整体提供安全保障。</span
  >
</p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >（3）对于基于熵基方案的定制产品的数据安全合规、隐私政策等相关信息，隐私政策声明以及法律合规性由客户负责，必要时候，熵基安全合规团队愿意提供安全解决方案的帮助和咨询服务。</span
  >
</p>
<p><strong>（九）数据备份</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >为防止数据因硬件故障、自然灾害等各种不可预见原因造成丢失，我们将定期备份云端数据库，并与（四）数据安全保持一致。备份频率为一天一备份，且备份数据加密存储，并在服务结束时在7天内依法删除您相关备份。</span
  >
</p>
<p><strong>五、责任</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >您作为数据控制者，必须对遵守适用的数据保护法规承担责任。您有义务评估存储在软件上的个人数据处理的合法性。您应确保向我们披露的任何个人数据披露均合法。</span
  >
</p>
<p><strong>六、审计权</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >您可以每年审核一次我们对协议条款和本数据处理协议的遵守情况。</span
  >
</p>
<p><strong>七、适用法律</strong></p>
<p style="text-indent: 18pt">
  <span
    style="
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      font-family: 宋体;
    "
    >本协议将受用户所在成员国的法律管辖。</span
  >
</p>
<p><br /></p>
`,
  },

  subscription: {
    Update: "更新",
    extend: "续费",
    more: "更多设备",
    subscription: "订阅",
    trial_package: "试用版",
    advanced_package: "专业版",
    starter_package: "基础版",
    professional_package: "专业版",
    summit_package: "峰会版",
    includes: "包含",
    free: "免费",
    freeTip: "免费提供给2台设备使用15天",
    getStarted: "开始使用",
    comingSoon: "敬请期待",
    customerName: "客户姓名",
    enterCustomerName: "请输入客户姓名",
    customerAccount: "客户账户",
    accountCreatedBy: "账户创建者",
    contractID: "合同ID",
    enterContractID: "请输入合同ID",
    contractAmount: "合同金额",
    enterContractAmount: "请输入合同金额",
    subscriptionPackage: "订阅套餐",
    subscriptionCreationDate: "订阅创建日期",
    expirationDate: "到期日期",
    activeDate: "激活日期",
    selectExpirationDate: "选择到期日期",
    selectActiveDate: "选择激活日期",
    numberOfDevice: "设备数量",
    enterNumberOfDevice: "输入设备数量",
    accountInformation: "账户信息",
    customerEmailAddress: "客户电子邮件地址",
    enterCustomerEmailAddress: "输入客户电子邮件地址",
    selectCustomer: "选择客户",
    area: "区域",
    enterArea: "请输入区域名称",
    mobileNumber: "手机号码",
    enterMobileNumber: "输入手机号码",
    contractInformation: "合同信息",
    amountUnit: "金额单位",
    selectAmountUnit: "选择金额单位",
    contractFile: "合同文件",
    uploadContract: "上传合同",
    uploadCompanyLogo: "上传公司徽标",
    submit: "提交",
    emailAddress: "电子邮件地址",
    clickSelectCustomer: "点击输入框选择客户",
    SelectCustomer: "选择客户",
    createCustomer: "创建客户",
    modifyTitle: "修改订阅",
    positive: "请输入正整数",
    tipMsg: "设备数量超出套餐限制，请删除设备并刷新页面",
    tipMsg2: "设备数量超出套餐限制，请联系管理员",
    tipMsg3: "您没有菜单权限，请联系管理员",
    payment: "付款",
    paymentMsg: "您确定要为当前订单付款吗？",
    singleMaterial: "单项内容",
    US$4: "US$4",
    US$10: "US$10",
    basic_package_tip: "每台设备/月（按年计费）US$11，每月计费",
    areaNameTip: "必须以字母或汉字开头，只能包含字母、数字、汉字、空格和下划线",
    payStatus: "支付状态",
    paid: "已支付",
    unpaid: "未支付",
    lengthTip: "长度不得超过 {{length}}。",
    minLengthTip: "长度必须大于 {{length}}。",
    moreThen: "金额不能为负。",
    numberMoreThen: "金额不能超过 {{number}}。",
    singleFile: "仅允许上传单个文件。",
    dropTip: "拖放到此处上传。",
    limtFileSize: "文件大小不能超过 {{tip}}。",
    Account_Information: "账户信息",
    contract_information: "合同信息",
    enter_Dev_number: "请输入设备数量",
    clinet_name: "客户名称",
    please_enter_clinet_name: "请输入客户名称",
    customer_email_address: "客户电子邮件地址",
    please_enter_email_address: "请输入客户电子邮件地址",
    email_formatted: "电子邮件格式不正确",
    enter_customer_code: "请输入客户代码",
    client_Code: "客户代码",
    please_select_location: "请选择地点",
    please_enter_first_name: "请输入名字",
    please_enter_last_name: "请输入姓氏",
    please_select_country: "请选择国家",
    please_select_province: "请选择省份",
    please_select_city: "请选择城市",
    please_formatted_phone: "请输入正确的手机号码",
    only_number: "只能输入数字",
    Monthly: "按月",
    Annually: "按年",
    extend_months: "延长订阅月份",
    extend_years: "延长订阅年限",
    subMonths: "订阅月份",
    subAnnually: "订阅年限",
    please_enter_number_months: "请输入月份数量",
    please_enter_number_Annually: "请输入年度数量",
    please_subscription_number: "请输入每月/年度订阅数量",
    please_select_zoonTime: "请选择时区",
    please_select_startTime: "请选择开始时间",
    please_select_endTime: "请选择结束时间",
    Contract_ID: "合同编号",
    please_enter_contract_Id: "请输入合同编号",
    please_enter_contract_amount: "请输入合同金额",
    please_enter_contract_unit: "请选择合同单位",
    Contract_Amount: "合同金额",
    Contract_Unit: "合同单位",
    Device_Count: "设备数量",
  },

  tenant: {
    tenant_mangerment: "租户管理",
    tenantId: "租户ID",
    phone: "联系电话",
    info: "公司简介",
    address: "公司地址",
    account: "账号邮箱",
    enter_company_name: "请输入公司名称",
    enter_account: "请输入账号(邮箱)",
    enter_info: "请输入公司简介",
    enter_address: "请输入公司地址",
    enter_phone: "请输入联系电话",
    addTenant: "新增租户",
    editTenant: "编辑租户",
    enter_address_required: "公司地址必填",
    Device_Count: "设备数量",
  },

  about: {
    media_personal_about: "关于",
    common_system_version: "版本",
    base_system_browsers: "推荐浏览器",
    base_system_resolution: "显示分辨率",
    common_system_resoltion_recommond: "建议的屏幕分辨率：1920x1080 或更高",
    common_package_details: "套餐详情",
    common_remaining_day: "剩余有效天数（天）",
    common_no_limit: "无限制",
    common_used_day: "已使用：{{day}} 天",
    common_gross: "总计",
    common_day: "天",
    common_remaining_device_count: "剩余设备数量（台）",
    common_add_device_num: "已添加：{{count}} 台",
    common_add_device_dot: "台",
    common_deviceType: "设备类型",
    common_please_type: "请选择设备类型",
  },

  system: {
    system_rule_password_length: "长度在 6 到 30 个字符",
    system_rule_email: "请输入正确的邮箱地址",
    system_about: "关于",
  },
};

export default zh;
